// Import just what we need

import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/dropdown';

import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import Toast from 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';


// Show toasts by default
const messagesContainer = document.getElementById('toastMessagesContainer')
if (messagesContainer !== null) {
    const toastElList = messagesContainer.querySelectorAll('.toast')
    const toastList = [...toastElList].map(toastEl => new Toast(toastEl))
    toastList.forEach(toast => toast.show())
}
